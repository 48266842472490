<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :icon="icon"
    :errors="errors"
    :is-active="isActive"
    :is-loading="isLoading || isInternalLoading"
    :feedback="feedback"
    :disable-disabled-opacity="disableDisabledOpacity"
    :is-disabled="isDisabled"
    :has-value="!!text"
    @click="() => input?.focus()"
    @keydown.enter="() => input?.focus()"
    @focus="
      () => {
        if (activateOnFocus) {
          input?.focus();
        }
      }
    "
  >
    <div class="input-text" v-class-mod:input-text="{ isDisabled }">
      <span class="input-text__input-wrapper">
        <span v-if="isDisabled" class="input-text__input">
          <cut-text :text="value || ''" />
        </span>
        <input
          v-else
          :placeholder="placeholder"
          :tabindex="!activateOnFocus ? -1 : 0"
          class="input-text__input"
          type="text"
          @focus="() => (isActive = true)"
          @blur="
            () => {
              isActive = false;
              updateValue(text);
            }
          "
          @keydown.enter.exact.stop="
            () => {
              blurOnEnter && input?.blur();
              emit('enter');
            }
          "
          @keydown.esc="
            () => {
              input?.blur();
              emit('escape');
            }
          "
          ref="input"
          v-model="text"
        />
      </span>

      <span v-if="unit && (!!value || !isDisabled)" class="input-text__unit">{{ unit }}</span>

      <icon-toggle-button
        v-if="canClear && !isDisabled && !!text"
        :tooltip="{ title: translations.inputs.clear }"
        :action="
          (e) => {
            e.stopPropagation();
            text = '';
            input?.focus();
          }
        "
        button-size="medium"
        icon="close"
        tabindex="0"
        class="input-text__clear"
      />
    </div>
  </base-label>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref, watch } from "vue";

import { delayFn } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import CutText from "@/components/content/cut-text.vue";
import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";
import { FeedbackReturnType } from "@/components/inputs/input-feedback.vue";

const IconToggleButton = defineAsyncComponent(() => import("@/components/buttons/icon-toggle-button.vue"));

const props = withDefaults(
  defineProps<
    PublicProps & {
      value?: string;
      placeholder?: string;
      canClear?: boolean;
      unit?: string;
      isDisabled?: boolean;
      autofocus?: boolean;
      activateOnFocus?: boolean;
      update?: (v?: string) => FeedbackReturnType;
      blurOnEnter?: boolean;
    }
  >(),
  { blurOnEnter: true, activateOnFocus: true },
);

const emit = defineEmits<{
  (e: "update", text: string): void;
  (e: "enter"): void;
  (e: "escape"): void;
  (e: "is-active", active: boolean): void;
}>();

const text = ref("");
const isActive = ref(false);
const input = ref<HTMLInputElement>();

const isInternalLoading = ref(false);
const feedback = ref<PublicProps["feedback"]>();

const updateValue = async (u: string) => {
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    const result = await props.update(u as unknown as string);
    if (result && "status" in result) {
      feedback.value = {
        ...result,
        callback: () => {
          feedback.value = undefined;
          result.callback?.();
        },
      };
    }
    isInternalLoading.value = false;
  }
};

const focus = async () => {
  if (!input.value) {
    await delayFn(() => !!input.value);
  }
  input?.value?.focus();
  input?.value?.select();
};

watch(
  () => props.value,
  (t) => (text.value = props.value === undefined || props.value === null ? "" : String(t)),
  { immediate: true },
);

watch(
  () => text.value,
  (t) => {
    if (text.value !== props.value?.toString()) {
      emit("update", t);
    }
  },
);

watch(
  () => isActive.value,
  (a) => emit("is-active", a),
);

onMounted(() => {
  if (props.autofocus) {
    window.requestAnimationFrame(focus);
  }
});

watch(
  () => props.autofocus,
  (has) => {
    if (has) {
      window.requestAnimationFrame(focus);
    }
  },
);
</script>

<style lang="scss" scoped>
@import "./mixins";
.input-text {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  &__input {
    @include input-reset;
    width: 100%;
    line-height: 1.28;
    min-width: var(--input-text-width);
    font-size: var(--app-font-size-base);
    &-wrapper {
      align-items: flex-end;
      display: flex;
      flex-flow: column;
      overflow: hidden;
      padding-left: var(--input-spacing, 0px);
      width: var(--input-text-width-formatted, 100%);
    }
  }
  &--isDisabled #{&}__input {
    pointer-events: none;
  }
  &__unit {
    white-space: nowrap;
  }
}
</style>
